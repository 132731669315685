import Layout from "../components/Layout"
import PageIntro from "../components/PageIntro2"
import Seo from "../components/Seo"

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
    <PageIntro
      text="404: Not Found"
      headline="This page doesn&#39;t exist."
      height="90vh"
    />
  </Layout>
)

export default NotFoundPage
