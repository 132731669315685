import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCircleArrowDown } from "@fortawesome/free-solid-svg-icons"

function PageIntro({ title, headline, headline2, text, height, minHeight }) {
  // const vHeight = useWindowHeight()
  // style={{ minHeight: 400, height: height ? height : vHeight }}

  return (
    <section className="w-full text-center sm:text-center flex flex-col items-center justify-center">
      {/* <section className="w-full text-left sm:text-center flex flex-col bg-cyan-100 items-center justify-center border-b border-b-px border-neutral-600" style={{ height: height ? height : vHeight }}> */}
      <h2 className="mt-36 sm:mt-48 text-5xl sm:text-6xl md:text-7xl lg:text-8xl 2xl:text-8xl mb-8 container">
        <div>{headline}</div>
        <div className="pt-2 block">{headline2}</div>
      </h2>
      {text && (
        <p className="text-base lg:text-lg sm:w-2/3 lg:w-2/3 text-neutral-500 font-bold tracking-wide container">
          {text}
        </p>
      )}
      {/* <div>
        <FontAwesomeIcon
          icon={faCircleArrowDown}
          className="block mt-20 mb-20 w-12 h-12 rounded-full animate-bounce shadow-xl"
        />
      </div> */}
    </section>
  )
}

export default PageIntro
